<template>
    <div class="body">
        <div class="container" v-if="info">
            <div class="task-list content full-width">
                <van-cell-group>
                    
                    <van-index-bar :index-list="indexList">
                        <van-index-anchor index="基本">基本情况</van-index-anchor>
                        <van-cell
                            v-for="(item, index) in config"
                            :key="item.key"
                            :title="item.key"
                          >
                            <template slot="label">
                              <strong class="custom-text" 
                                style="font-size: 14px;color: #333;">
                              {{item.val|| '&nbsp;-'}}</strong>
                              <van-tag
                                plain
                                style="margin-left: 20px;"
                                color="#ff976a"
                                size="large"
                                v-if="index == 0">
                                {{getStatusText(info.status)}}
                              </van-tag>
                            </template>
                        </van-cell>
                        <van-cell v-if="info.status == -1">
                           <template slot="title">
                              <strong class="custom-text" style="font-size: 14px;color: #ff976a;">
                              反馈：{{info.confirm_msg}}</strong>
                            </template>
                        </van-cell>
                        <template v-for="(d, index) in details" >
                            <van-index-anchor 
                                :index="indexList[index]" 
                                :key="index">{{d.att_date}}
                            </van-index-anchor>
                            <van-cell
                                :title="o.key"
                                :key="i"
                                v-for="(o, i) in d.detail"
                              >
                                <template slot="label">
                                  <strong class="custom-text" 
                                    style="font-size: 14px;color: #333;">
                                  {{o.val || '&nbsp;-'}}</strong>
                                  
                                </template>
                            </van-cell>
                        </template>
                    </van-index-bar>
                  
                </van-cell-group>
            </div>
            <div class="footer full-width" v-if="info && info.status != 1">
                <van-button color="#1989fa" @click="confirmAttendance" >确认签名</van-button>
            </div>
        </div>
        <pop-hr-confirm ref="popConfirm"></pop-hr-confirm>
    </div>
</template>

<script>
    import {toast , checkLogin} from '@/utils/common.js';
    import { getMyAttendanceTodoDetail, confirmMyAttendance} from '@/api/task.js';
    import PopHrConfirm from '@/components/PopHrConfirm.vue';
    import { mapState } from 'vuex';
    var aid = null;
    export default{
        data(){
            return {
                value: 20,
                info: null,
                config: null,
                details: [],
                indexList: [],
                activeKey: 0,
                tagStyle:{
                    // position: 'absolute',
                    // left:'120px',
                    // bottom: '10px',
                    // transform: 'rotate(20deg)',
                }
            }
        },
        components:{
            PopHrConfirm
        },
        activated(){
            var batch = batch = this.$route.query.batch;
            console.log('this.$route.query.batch', this.$route.query.batch);
            checkLogin().then(()=> {
                this.getDetail(batch);
            })
            this.hideTab();
         },
         computed: mapState(['token']),
         methods:{
            onChange(index) {
                this.activeKey = index;
            },
            confirmAttendance(){
                var pop = this.$refs.popConfirm;
                pop.show = true;
            },
            onConFirm(data){
                console.log('commit', data);
                
                confirmMyAttendance(aid, data).then(()=>{
                    this.getDetail(aid);
                }).catch((err)=>{
                    toast(err.msg || '确认失败');
                });
            },
            getDetail(batch){
                getMyAttendanceTodoDetail(batch).then((ret)=>{
                    this.info = ret.data;
                    aid = ret.data.id;
                    // this.$set(this, 'info', ret.data)
                    this.config = JSON.parse(this.info.info);
                    var details = this.info.details;
                    this.details = details;
                    if(!details || details.length == 0){
                        return;
                    }
                    this.indexList[0] = '基本';
                    for (var i = 0; i < details.length; i++) {
                        this.indexList.push(`${i+1}`);
                        var item = details[i];
                        details[i]['detail'] = JSON.parse(item['detail']);
                    }
                }).catch((err)=>{
                    toast(err.msg || '获取失败');
                });
             },
            getStatusText(status){
                switch (status){
                    case 0:
                        return '未确认';
                    case 1:
                        return '已确认';
                    case -1:
                        return '有异议';
                    default:
                        return '未知';
                 }
             },
             hideTab(){
                 //console.log(document.querySelector('#vantab'));
                 document.querySelector('#vantab').classList.add('tab-hide');
             }
         },
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .container{
       font-size: 14px; 
       bottom: 0;
       max-height: 100%;
    }
    .with-footer{
        bottom: 50px !important;
    }
    .content{
        height: 100%;
        overflow: auto;
        padding-bottom: 50px;
    }
    .line{
        height: 1px;
        background-color: #ccc;
        margin: 10px 0 10px;
    }
    .tabs{
        display: flex;
        margin: 10px 0 6px;
        font-size: 14px;
        font-weight: 400;
    }
    .tab-item{
        margin-right: 10px;
    }
    .tab-item.cur{
        color: #465acd;
    }
    .title-class, .value-class, .label-class{
        font-size: 14px;
    }
    .van-tabbar--fixed{
        display: none !important;
    }
    .footer{
        position: fixed;
        width: 100vw;
        height: 4.5rem;
        bottom: 5px;
        border-top: 1px solid #e8e8e8;
    }
    .footer button{
        margin-top: 0.5rem;
        height: 3.5rem;
        width: 34.5rem;
        line-height: 3.5rem;
        font-size: 1.4rem;
        background-color: #465ecc;
        margin-left: 1.5rem;
    }
    /* .my-tag{
        border-radius: 50%;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #f2826a;
        border: 1px solid #f2826a;
        position: absolute;
        left:120px;
        bottom: 0;
        transform: rotate(20deg);
        font-size: 12px;
    } */
</style>